/* eslint-disable @typescript-eslint/no-explicit-any,camelcase */
import React from 'react'
import {graphql} from 'gatsby'
import {Helmet} from 'react-helmet'
import {Layout} from '../components/blog'
import {MetaData} from '../components/blog/meta'

type Props = {
  data: {
    ghostPost: {
      codeinjection_styles?: any
      title: string
      html: string
      feature_image?: string
    }
  }
  location: any
}

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({data, location}: Props) => {
  const post = data.ghostPost

  return (
    <>
      <MetaData data={data} location={location} type="article" />
      <Helmet>
        <style type="text/css">{`${post.codeinjection_styles}`}</style>
      </Helmet>
      <Layout title={post.title}>
        <div className="container">
          <article className="content">
            {post.feature_image ? (
              <figure className="post-feature-image">
                <img src={post.feature_image} alt={post.title} />
              </figure>
            ) : null}
            <section className="post-full-content">
              {/*<h1 className="content-title">{post.title}</h1>*/}

              {/* The main post content */}
              <section
                className="content-body load-external-scripts"
                dangerouslySetInnerHTML={{__html: post.html}}
              />
            </section>
          </article>
        </div>
      </Layout>
    </>
  )
}

export default Post

export const postQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: {eq: $slug}) {
      ...GhostPostFields
    }
  }
`
